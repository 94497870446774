import React from 'react';
import {css} from '@emotion/core';
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import logo from '../assets/logo.svg';

const initMarker = (ref) => {
  if (ref) {
    ref.leafletElement.openPopup();
  }
};

class TalenMap extends React.Component {

  render() {
    const position = [
      // -38.086352,
      // 145.713753
      -26.8044,
      153.1255
    ],
    Melbourne = [
      -37.8136,
      144.9631
    ],
    Sydney = [
      -32.2444,
      148.6144
    ];

    if (typeof window !== 'undefined') {

      return (
        <Map css={css`height: 40vh;`} center={Sydney} zoom="5">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker ref={initMarker} position={position}>
            {/* <Popup>
              <img src={logo} alt="Talen" height="30" />
            </Popup> */}
          </Marker>
          <Marker ref={initMarker} position={Melbourne}>
            {/* <Popup>
              <img src={logo} alt="Talen" height="30" />
            </Popup> */}
          </Marker>
        </Map>
      );
    }

    return null;
  }
}

export default TalenMap;
