import React from 'react';
import {css} from '@emotion/core';
import Layout from '../components/layout';
import Intro from '../components/intro';
import Map from '../components/leaflet-map';

const Contact = () => (
  <Layout page="Contact Us">
    <Intro>
      <h2>Get in touch and see how Talen can help you and your business</h2>
    </Intro>

    <section css={css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      align-items: center;

      @media only screen and (max-width : 750px) {
        flex-direction: column;
      }

      & > div {
        width: 48%;
        display: block;
        border-radius: 15px;

        @media only screen and (max-width : 750px) {
          width: 100%;
        }
      }
    `}>
      <div css={css`margin-bottom: 2rem;`}>
        <p css={css`font-size: 1.2rem; margin-bottom: 2rem;`}>With a distributed team from the Sunshine Coast to Melbourne, we help support businesses right across Australia and around the globe.</p>
        <p css={css`font-size: 1.2rem; margin-bottom: 2rem;`}>Send us an email, give us a call, or make an enquiry today. You won't be disappointed.</p>
        <ul>
          <li>Email: <a href="mailto:info@talen.com.au">info@talen.com.au</a></li>
          {/* <li>Phone: <a href="tel:(03) 5611 0428">(03) 5611 0428</a></li> */}
          <li>Locations: Sunshine Coast, Melbourne</li>
        </ul>
      </div>
      <Map />
    </section>
  </Layout>
);

export default Contact;
